import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './img1.jpg'
import pic2 from './img2.jpg'
import pic3 from './img3.jpg'

const Page = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'PREZODE initiative: kick-off meeting of the ASAMCO project in Laos and Thailand',
            href:'',
            element:'',
            topic:'On December 13, the various parties and partners gathered in Bangkok (Thailand) to mark the official launch of the ASAMCO project in the region, the second component of the PREACTS project developed as part of the international PREZODE initiative.',
            inform:`Hosted at Kasetsart University's Faculty of Veterinary Technology, the kick-off meeting opened with welcome speeches from Dr. Chongrak WACHRINRAT (President of Kasetsart University), Dr. Wuttinun RAKSAJIT (Dean of the Faculty of Veterinary Technology) and H.E. Sir Jean-Claude POIMBOEUF (French Ambassador to Thailand). He stressed the importance of the One Health approach in preventing the emergence and spread of zoonotic diseases, and thanked the work and collaboration of French scientific operators in Thailand and the region.
            `
        ,
            link1:`https://en.ird.fr/prezode-initiative-kick-meeting-asamco-project-laos-and-thailand`,
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
            thumbnailImageSrc: pic3
        },
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
