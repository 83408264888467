import React, { useEffect, useState } from 'react'
import './css/publication.css'

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator'

import { Outlet, Route, Routes } from 'react-router-dom'

import publications from '../localfiles/local-publications'

import ErrorPage from './error'

const Publications = () => {
  const handleUp = () =>{
    window.scrollTo({top:0})
  }

    const [active, setActive] = useState('')
    const handlePopup = (e) =>{
        const key = e.currentTarget.getAttribute('data-key')
        setActive(prevActive => prevActive === key ? '' : key)
    }

    useEffect(()=>{
        const popUp = () =>{
            if(active){
                const elements = document.querySelectorAll(`.containers ${active}`)
                elements.forEach(element =>{
                    element.classList.toggle('active')
                })
            }else{
                return
            }
        }
        popUp()
    },[active])

    // FILTER Catalogs
    const [filterInput, setFilterInput] = useState('')
    const [filteredData, setFilterData] = useState([])

    const [search, setSearch] = useState('')
    const handleSearch = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setSearch(value)

        const filtered = publications.filter((publication)=>
          publication.topic.toLowerCase().includes(value.toLowerCase()) ||
          publication.author.toLowerCase().includes(value.toLowerCase())
        )
        setFilterData(filtered)
    }

    const [Year, setYear] = useState(false)
    const handleYear = (e) =>{
        const value = e.target.value
        setYear(value)
        setFilterInput(value)

        const filtered = publications.filter((publication) =>
          publication.year.toString().includes(value.toString())
        )
        setFilterData(filtered)
    }

    const years = [
        {
            id: 0,
            year: false,
            label: 'All'
        },
        {
            id:4,
            year: 2024,
            label: 2024
        },
        {
            id:3,
            year: 2023,
            label: 2023
        },
        {
            id: 2,
            year: 2022,
            label: 2022
        },
        {
            id: 1,
            year: 2021,
            label: 2021
        },
    ]

    // Maximum containers
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerpage, setRowPerPage] = useState(5)


    // calculate data display
    const paginatedData = publications.slice(currentPage * rowsPerpage, (currentPage + 1) * rowsPerpage)
    // Handle page change
    const onPageChange = (e) => {
        setCurrentPage(e.page)
        setRowPerPage(e.rows)
        handleUp()
    }

    const publicationRoutes = publications.map((publication) => (
        <Route path={publication.href} element={<publication.element />}/>
    ))
    const dataToDisplay = filterInput ? filteredData : paginatedData
    const totalRecords = filterInput ? filteredData.length : publications.length

    return(
        <div className="publications">
            <div className="people-modal" style={{display: active? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>PUBLICATIONS</h1>
                </div>
            </div>

            {/* Search input */}
            <div className="filter">
                <div className="items search">
                    <div className="head">
                        <b>Search: </b>
                    </div>
                    <div className="search">
                        <InputText type="text" placeholder="enter search term..." onChange={handleSearch} value={search} disabled={ Year !== false}/>
                        <i className="pi pi-search"></i>
                    </div>
                </div>

               <div className="dropdown">
                {/* FILTER by YEAR */}
                    <div className="items dropdown year">
                        <div className="head">
                            <b>Year: </b>
                        </div>
                        <div className="year">
                            <Dropdown value={Year} onChange={handleYear} options={years} optionLabel='label' optionValue='year' placeholder='All' disabled={search !== '' }/>
                        </div>
                    </div>
               </div>
            </div>

            <Routes>
                <Route index element={
                  <div className="publications">
                    {dataToDisplay.map((publication) => (
                      <div className={`containers ${publication.id}`} key={publication.id}>
                        <a className='items' href={publication.link} target='_blank' rel='noreferrer'>
                          <div className="topic"><h3>{publication.topic}</h3></div>
                          <div className="authors"><h4 style={{fontStyle: 'italic', color: '#B7B7B7'}}>{publication.author}</h4></div>
                            <div className="details">
                              <div className="publish"><h5>{publication.publish}</h5></div>
                              <div className="book"><h5>{publication.book}</h5></div>
                            </div>
                        </a>
                      </div>
                    ))}
                    
                  </div>
                }/>
                {publicationRoutes}
                <Route path='*' element={<ErrorPage/>} />
            </Routes>
            <Outlet />  

            <div className="paginator">
                <Paginator
                    first={currentPage * rowsPerpage}
                    rows={rowsPerpage}
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default Publications