import './css/aboutus.css'

import blank from './aboutus/img/blank.png'
import wk from './aboutus/img/wk.jpg'
import sl from './aboutus/img/sl.jpg'
import nnk from './aboutus/img/nnk.jpg'
import sp from './aboutus/img/sp.jpg'

import React, { useEffect, useState } from 'react'
import Parse from 'html-react-parser'

// logo
import logo from './aboutus/img/presto-logo.png'

// import Laos partners
import anourak from './aboutus/img/col/anourak-logo.jpg'
import ecc from './aboutus/img/col/ecc-logo.jpg'
import ftb from './aboutus/img/col/ftb-logo.png'
import lctw from './aboutus/img/col/lctw-logo.jpg'
import wwf from './aboutus/img/col/wwf-logo.jpg'
// import France partners
import mivegec from './aboutus/img/col/lg_mivegec.png'
import ird from './aboutus/img/col/ird-logo.png'
import montperiller from './aboutus/img/col/montpellier.png'
// import Thailand partners
import cmu_ams from './aboutus/img/col/amscmu.png'
import cmu_vetmed from './aboutus/img/col/vetmedcmu.jpg'
import cmu_sci from './aboutus/img/col/scicmu.jpg'
import cmu_sosci from './aboutus/img/col/soscicmu.png'

import pho from './aboutus/img/col/pho.jpg'
import doichiangdao from './aboutus/img/col/doichiangdao.jpg'

import mu_trop from './aboutus/img/col/mu_trop.png'
import ku_vettech from './aboutus/img/col/ku_vettech.png'

// import aims
import dna from './aboutus/img/aims/dna.png'
import gear from './aboutus/img/aims/gear.png'
import bat from './aboutus/img/aims/bat.png'
import pin from './aboutus/img/aims/pin.png'
import people from './aboutus/img/aims/people.png'
//  aims bgimg
import bgdna from './aboutus/img/aims/bgdna.png'
import bggear from './aboutus/img/aims/bggear.png'
import bgbat from './aboutus/img/aims/bgbat.png'
import bgpin from './aboutus/img/aims/bgpin.png'
import bgpeople from './aboutus/img/aims/bgpeople.png'


import { Annotation, ComposableMap, Geographies, Geography } from 'react-simple-maps'
import worldMap from './aboutus/countries-110m.json'

const Aboutus = () => {

    const aims = [
        {
            id:1,
            head: 'AIM 1',
            details: 'Molecular characterization /epidemiology of pathogens of zoonotic potential',
            imgSrc: dna,
            bgimg: bgdna
        },
        {
            id:2,
            head: 'AIM 2',
            details: 'Identify animal reservoirs',
            imgSrc: bat,
            bgimg: bgbat
        },
        {
            id:3,
            head: 'AIM 3',
            details: 'Identify biodiversity rich areas/ anthropogenic pressure',
            imgSrc: pin,
            bgimg: bgpin
        },
        {
            id:4,
            head: 'AIM 4',
            details: 'Identify behavior risks of exposure and susceptible human populations',
            imgSrc: people,
            bgimg: bgpeople
        },
        {
            id:5,
            head: 'AIM 5',
            details: 'New detection tests/ validation',
            imgSrc: gear,
            bgimg: bggear
        },
    ]

    const coordinators = [
        {
            id:3,
            ini: "wk",
            name: 'Woottichai Khamduang',
            orcid: 'https://orcid.org/0000-0003-0196-5691',
            orcidlink: 'https://orcid.org/0000-0003-0196-5691',
            cv: `Woottichai Khamduang, is Assistant Prof at the Faculty of Associated Medical Sciences, Chiang Mai University since 2016. His activities include teaching, research, and community health services. He holds dual PhD degrees in Biomedical Sciences and Life and Health Sciences from Chiang Mai University and Université François Rabelais, respectively.  His research interests focus on addressing critical health challenges and improving access to and diagnosis of infectious diseases. He has extensive expertise in virology and medical technology and has been involved in numerous high-impact research projects, particularly on HIV, hepatitis B, and emerging infectious diseases. His academic and research endeavors have positioned him as a leader in virology and public health in the region.`,
            email: `woottichai.k@cmu.ac.th`,
            imgSrc: wk
        },
        {
            id:2,
            ini: "sl",
            name: 'Sabrina Locatelli',
            orcid: 'https://orcid.org/0000-0002-1133-0561',
            orcidlink: 'https://orcid.org/0000-0002-1133-0561',
            cv: `Sabrina Locatelli is a Swiss researcher specializing in molecular biology, epidemiology, and conservation, with a focus on zoonotic diseases and wildlife sampling. She earned her Doctorate in Public Health and Epidemiology from the University of Basel and completed postdoctoral work in the USA. Sabrina has led international research projects, particularly in Laos, where she has been based since 2021 and currently serves as the IRD representative. She is recognized for her contributions to global health research. She is also actively involved in teaching, capacity-building, and scientific diplomacy, with fluency in multiple languages.`,
            email: `sabrina.locatelli@ird.fr`,
            imgSrc: sl
        },
        {
            id:1,
            ini: "nnk",
            name: 'Nicole Ngo-Giang-Huong',
            orcid: 'https://orcid.org/0000-0001-8950-3234',
            orcidlink: 'https://orcid.org/0000-0001-8950-3234',
            cv: `Nicole Ngo-Giang-Huong is research director at the MIVGEC unit, French National Research Institute for Sustainable Development. She holds a PharmD degree from Paris XI and a PhD in Immunology from University Paris VII Denis Diderot. She has extensive experience in medical biology, particularly clinical virology. She has led various projects on the molecular analysis of HIV, HCV and HBV in Southeast Asia since 2001. She has been member of several scientific committees and international networks.`,
            email: `nicole.ngo-giang-huong2@ird.fr`,
            imgSrc: nnk
        },
        {
            id:4,
            ini: 'sp',
            name: 'Sysay Palamy',
            orcid: 'https://orcid.org/0000-0001-6510-1097',
            orcidlink: 'https://orcid.org/0000-0001-6510-1097',
            cv: `Sysay Palamy is a distinguished academic and researcher, currently serving as the Head of the Department of 
            Pharmaceutical Sciences at the Faculty of Pharmacy, University of Health Sciences, Laos. He also holds the position of 
            Sub-committee of Biotechnology-Laos. His research expertise lies in pharmaceutical sciences, with a particular emphasis on 
            quality control of pharmaceutical products, including foods, cosmetics, herbal medicine, and modern medicine. His research interests 
            extend to the field of One Health, focusing on issues such as heavy metal contamination in the environment and food products, antibiotic 
            residue in meat, and lead exposure in nurseries, primary schools, and toys. He is also passionate about sustainable extraction techniques, 
            specifically Supercritical Fluid Extraction (SFE-CO2) and Pressurized Liquid Extraction (PLE). He has been an active participant in several 
            international research collaborations, including the COCLICAN project (2019-2022), the ROCKET project (2021-2023), and the Mekong Pharma 
            Network project (Laos, Vietnam, and Cambodia) in 2022-2024.`,
            email: `sysaypharma@gmail.com`,
            imgSrc: sp
        }
    ]

    const [active, setActive] = useState('')
    const handlePopup = (e) =>{
        const key = e.currentTarget.getAttribute('data-key')
        setActive(prevActive => prevActive === key ? '' : key)
    }

    useEffect(()=>{
        const popUp = () =>{
            if(active){
                const elements = document.querySelectorAll(`.containers ${active}`)
                elements.forEach(element =>{
                    element.classList.toggle('active')
                })
            }else{
                return
            }
        }
        popUp()
    },[active])

    const geoUrl = worldMap
    const defaultColor = "#b0bec5"
    const CountryCode = {
        'Thailand': `#00b55b`,
        'Laos': '#00b55b',
        'France': '#00b55b'
    }
    const partners = [
        {
            id: "th",
            name: "Thailand",
            x: 50,
            y:30,
            logo:[
                {id: 'cmu_ams',src: cmu_ams,href: 'https://www.ams.cmu.ac.th/main/web/' },
                {id: 'cmu_vetmed',src: cmu_vetmed,href: 'https://www.vet.cmu.ac.th/web2022/index.html' },
                {id: 'cmu_sci',src: cmu_sci,href: 'https://www.science.cmu.ac.th/prsci/index.php' },
                {id: 'cmu_sosci',src: cmu_sosci,href: 'https://www.soc.cmu.ac.th/' },
                {id: 'mu_trop', src: mu_trop, href: 'https://tm.mahidol.ac.th/'},
                {id: 'ku_vettech', src: ku_vettech, href:'https://www.ku.ac.th/en/faculty-of-veterinary-technology'},
                {id: 'pho', src: pho, href: 'https://www.chiangmaihealth.go.th/'},
                {id: 'doichiangdao', src: doichiangdao, href: 'https://www.dnp.go.th/wildlife/wildstation/001%20Doi%20ChaeangDao.html'},
            ]
        },
        {
            id: "fr",
            name: "France",
            x: 20,
            y:5,
            logo:[
                {id: 'mivegec',src: mivegec,href: 'https://mivegec.fr/en' },
                {id: 'ird', src: ird, href: 'https://www.ird.fr/'},
                {id: 'montperiller', src: montperiller, href: 'https://sciences.edu.umontpellier.fr/en/welcome-to-the-faculty-of-sciences/'}
            ]
        },
        {
            id: "la",
            name: "Laos PDR",
            x: 50,
            y:10,
            logo:[
                {id: 'ecc',src: ecc,href: 'https://www.elephantconservationcenter.com' },
                {id: 'wwf',src: wwf,href: 'https://www.wwf.org.la' },
                {id: 'ftb',src: ftb,href: 'https://freethebears.org' },
                {id: 'lctw',src: lctw,href: 'https://lctwildlife.org' },
                {id: 'anourak',src: anourak,href: 'https://www.conservationlaos.com' }
            ]
        }
    ]

    return(
        <div className="aboutus">
            <div className="aboutus-modal" style={{display: active ? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>ABOUT US</h1>
                </div>
            </div>

{/* ABOUT */}
            <div className="about">
                <div className="container">
                    <div className="text">
                        <p>The International Joint Laboratory (or Laboratoire Mixte International) <b>PRESTO</b> for <b>PR</b>otect-d<b>E</b>tect-<b>STO</b>p  aims to create a sustainable platform for research, diagnosis and training on emerging diseases at the human-animal-environment interface. IJL PRESTO includes researchers from various background and expertise covering Virology, bacteriology, infectious diseases, veterinary medicine, public health, ecology, social science, environmental science.
                            <br />This multidisciplinary team aims to:
                        </p>
                        <ul>
                            <li>Rapid identification of emerging zoonoses using epidemiological, behavioral and molecular studies in the environment, animals and humans</li>
                            <li>Development, validation and evaluation of new tools for prevention, diagnosis and surveillance</li>
                            <li>Filling knowledge gaps by acting as a hub for South East Asian researchers and students</li>
                        </ul>
                    </div>
                    <div className="img">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </div>
            <hr />

{/* AIMS */}
        <div className="aims">
            <div className="container">
                {aims.map((aim) => (
                    <div className="items" style={{position:'relative'}}>
                        <div className='bgimg' style={{ 
                                backgroundImage: `url(${aim.bgimg})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                height: "100%", 
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                opacity: 0.3,
                                zIndex: -1,

                            }}>
                        </div>
                        <div className="img">
                            <img src={aim.imgSrc} alt="img" />
                        </div>
                        <div className="text">
                            <div className="head">
                                <b><u>{aim.head}</u></b>
                            </div>
                            <div className="details">
                                <p>{aim.details}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <hr />

{/* coordinators */}
            <div className="leaders">
                <h2>Coordinators</h2>
                {coordinators.map((coordinator) => (
                    <div key={coordinator.id} className="leader-profile" data-key={coordinator.ini} onClick={handlePopup}>
                        <div className="profile-img">
                            <div className="img">
                                <img src={coordinator.imgSrc ? coordinator.imgSrc : blank} alt="profile" />
                            </div>
                            <div className="text">
                                <p>{coordinator.name}</p>
                                <a href={coordinator.orcidlink} target='_blank' rel='noreferrer'>{coordinator.orcid}</a>
                                <a href={`mailto:${coordinator.email}`}>{coordinator.email}</a>
                            </div>
                        </div>
                        <div className="cv">
                            <div className={`cv-cover-${coordinator.ini}`}>
                                <div className="text-bg"></div>
                                <div className="cv-text">
                                    <p>{Parse(coordinator.cv)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>


{/* World Map */}
            <div className="partners">
                <h2>Partners</h2>
                <div className="worldmap">
                    {partners.map((partner) => (
                        <div className={`containers`} data-key={partner.id} style={{left:`${partner.x}vw`, top: `${partner.y}vw`}}>
                            <div className="head">{partner.name}</div>
                            <div className="logo">
                                {Array.isArray(partner.logo) ? (
                                    partner.logo.map((logo) => (
                                        <a href={logo.href} key={logo.id} target='_blank' rel='noreferrer'>
                                            <img src={logo.src} alt={logo.id} />
                                        </a>
                                    ))
                                ) : (
                                    <p>No partners available</p>
                                )}
                            </div>
                        </div>
                    ))}
                <ComposableMap>
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                            geographies.map((geo) => {
                                const countryName = geo.properties.name
                                const cursorMap = CountryCode[countryName] ? "pointer" : 'context-menu'
                                return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    style={{
                                        default:{
                                            fill :CountryCode[countryName] || defaultColor,
                                            stroke: "#FFFFFF",
                                            strokeWidth: .1,
                                        },
                                        hover:{
                                            fill: CountryCode[countryName] ? "#006533" : defaultColor,
                                            cursor: cursorMap
                                        },
                                        pressed:{
                                            fill: CountryCode[countryName] ? "#006533" : defaultColor,
                                        }
                                    }}
                                />
                                );
                            })
                            }
                        </Geographies>
                        {/* france */}
                        <Annotation
                            subject={[2.3522, 48.8566]}
                            dx={-90}
                            dy={-30}
                            connectorProps={{
                            stroke: "#006533",
                            strokeWidth: 1,
                            strokeLinecap: "round"
                            }}
                        ></Annotation>
                        {/* laos */}
                        <Annotation
                            subject={[102,20]}
                            dx={-50}
                            dy={-60}
                            connectorProps={{
                            stroke: "#006533",
                            strokeWidth: 1,
                            strokeLinecap: "round"
                            }}
                        ></Annotation>
                        {/* thailand */}
                         <Annotation
                            subject={[70,0]}
                            dx={80}
                            dy={-55}
                            connectorProps={{
                            stroke: "#006533",
                            strokeWidth: 1,
                            strokeLinecap: "round"
                            }}
                        ></Annotation>
                </ComposableMap>
                </div>
            </div>


{/* POPUP */}
            <div className="leaders-popup popup">
                {coordinators.map((coordinator) =>(
                    <div key={coordinator.id} className={`containers ${coordinator.ini} ${active === coordinator.ini ? 'active' : ''}`} >
                        <div className="cross" onClick={handlePopup}>
                            <i className="pi pi-times"></i>
                        </div>
                        <div className="leaders-img">
                            <img src={coordinator.imgSrc ? coordinator.imgSrc : blank} alt="profile" />
                        </div>
                        <div className="text">
                            <div className="head"><h2>{coordinator.name}</h2></div>
                            <div className="email">
                                <a href={coordinator.orcidlink} target='_blank' rel='noreferrer'>{coordinator.orcid}</a>
                                <a href={`mailto:${coordinator.email}`}>{coordinator.email}</a>
                            </div>
                            <div className="cv">{Parse(coordinator.cv)}</div>
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className="members-popup popup">
                {members.map((member) =>(
                    <div key={member.id} className={`containers ${member.ini} ${active === member.ini ? 'active' : ''}`} >
                        <div className="cross" onClick={handlePopup}>
                            <i className="pi pi-times"></i>
                        </div>
                        <div className="members-img">
                            <img src={member.imgSrc ? member.imgSrc : blank} alt="profile" />
                        </div>
                        <div className="text">
                            <div className="head">
                                <h2>{member.name}</h2>
                                <h3>{member.position}</h3>
                            </div>
                            <div className="email"><a href={`mailto:${member.email}`}>{member.email}</a></div>
                            <div className="cv">{member.cv}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="students-popup popup">
                {students.map((student) =>(
                    <div key={student.id} className={`containers ${student.ini} ${active === student.ini ? 'active' : ''}`} >
                        <div className="cross" onClick={handlePopup}>
                            <i className="pi pi-times"></i>
                        </div>
                        <div className="students-img">
                            <img src={student.imgSrc} alt="profile" />
                        </div>
                        <div className="text">
                            <div className="head">
                                <h2>{student.name}</h2>
                                <h3>{student.position}</h3>
                            </div>
                            <div className="email"><a href={`mailto:${student.email}`}>{student.email}</a></div>
                            <div className="cv">{student.cv}</div>
                        </div>
                    </div>
                ))}
            </div> */}
        </div>
    )
}

export default Aboutus