import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'

const Page = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'International One Health Conference. Faculty of Associated Medical Sciences, Chiang Mai University, Thailand',
            href:'',
            element:'',
            topic:'',
            inform: `On 8th - 9th January 2025, the international conference <b>2025 One Health Conference</b>: Tackling Air Pollution, Respiratory Diseases, Antimicrobial Resistance and Host Immunity at the Human, Animal, and Environment Interface
            has been organized by Faculty of Associated Medical Sciences, Chiang Mai University, Thailand at Shangri-La Hotel Chiang Mai, Thailand. For sharing about academic information and to support One-Health research topics in the future.
            In collaboration with professors and specialists from Faculty of Associated Medical Sciences, Faculty of Sciences, and Faculty of Veterinary Medicine Chiang Mai University. "<b>PRESTO: Understanding and mitigating zoonotic spillover events in
    declining biodiversity hot-spots in Southeast Asia</b>" has been presented by Asst. Prof. Woottichai Khamduang (the PRESTO coordinator in Thailand)`,
            link1: `https://www.ams.cmu.ac.th/main/web/site/article?id=4175`,
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
            thumbnailImageSrc: pic3
        },
        {
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
            thumbnailImageSrc: pic4
        },
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
