import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'
import pic5 from './pic5.jpg'
import pic6 from './pic6.jpg'


const Page = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'Training workshop: using heritage policies to support the One Health approach',
            href:'',
            element:'',
            topic:'On December 3-7, 2024, a 4-day training workshop entitled “Leveraging heritage to tackle One Health challenges” exposed 20 Lao students and academics to key concepts, methods, and case studies on environmental heritage to tackle pressing One Health challenges for global health security.',
            inform:`One Health is central for the attainment of global health and includes critical challenges such as the risk of pandemics from zoonotic disease emergence, antimicrobial resistance, or food insecurity through environmental degradation and contamination.
            Held with the support of IJL PRESTO, the British Academy funded research project “Towards Heritage-Sensitive Climate Change Mitigation Policy: Impulses from Indigenous Practice in Thailand” at Chiang Mai University, and the Agence Universitaire de la Francophonie (AUF) in Laos, the workshop explored an innovative concept to promote One Health from a participatory social science perspective: everyday environmental heritage. The activities involved 20 participants, including academics and students from the University of Health Sciences and the National University of Laos, international instructors with cutting-edge expertise in various facets of heritage studies and conservation, and researchers of IRD– plus members of local communities.
            The participatory setup of the workshop involved not only the training of the Lao participants but also opened mutual learning and exchange opportunities between the participants and the villagers themselves. They problematized the situation of their local ecosystem and the critical role of heritage therein, and expressed a demand for more universal education on these issues in Laos.
            `,
            link1:`https://en.ird.fr/training-workshop-using-heritage-policies-support-one-health-approach`,
            link2:`https://www.facebook.com/share/p/18Ch26S5H3/?mibextid=wwXIfr`
}
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }
        const handleLink2 = () =>{
            window.open(detail.link2, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
            thumbnailImageSrc: pic3
        },
        {
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
            thumbnailImageSrc: pic4
        },
        {
            imageSrc: pic5,
            alt: 'pic5',
            title: 'pic5',
            thumbnailImageSrc: pic5
        },
        {
            imageSrc: pic6,
            alt: 'pic6',
            title: 'pic6',
            thumbnailImageSrc: pic6
        }
    ]

    return (
        <div className='elements containers'>
             <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
