const Publications = [
    {
        id: 10,
        topic: 'Seroprevalence and associated factors of HIV, syphilis, hepatitis B, and hepatitis C infections among sex workers in Chiangmai, Thailand during easing of COVID-19 lockdown measures',
        author: 'Sayamon Hongjaisee ,Woottichai Khamduang,Nang Kham-Kjing, Nicole Ngo-Giang-Huong, Arunrat Tangmunkongvorakul',
        publish: 'Published: December 2024',
        year: 2024,
        book: 'PLOS ONE 19(12): e0316668 ;https://doi.org/10.1371/journal.pone.0316668',
        link: 'https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0316668'
    },
    {
        id: 9,
        topic: 'Seroprevalence of SARS-CoV-2 nucleocapsid antibody among sex workers during the 5th epidemic wave with Omicron variant in Chiang Mai, Thailand',
        author: 'Sayamon Hongjaisee, Ratchadakorn Guntala, Arunrat Tangmunkongvorakul , Nicole Ngo-Giang-Huong and Woottichai Khamduang',
        publish: 'Published: August 2024',
        year: 2024,
        book: 'Heliyon Volume 10, Issue 17;https://doi.org/10.1016/j.heliyon.2024.e36807',
        link: 'https://www.sciencedirect.com/science/article/pii/S2405844024128381?via%3Dihub'
    },
    {
        id: 8,
        topic: 'Contamination of Streptococcus suis and S. suis Serotype 2 in Raw Pork and Edible Pig Organs: A Public Health Concern in Chiang Mai, Thailand',
        author: 'Ratchadakorn Guntala, Likhitphorn Khamai, Nattawara Srisai, Sakaewan Ounjaijean, Woottichai Khamduang and Sayamon Hongjaisee',
        publish: 'Published: July 2024',
        year: 2024,
        book: 'Foods 2024, 13(13), 2119;https://doi.org/10.3390/foods13132119 (registering DOI)',
        link: 'https://www.mdpi.com/2304-8158/13/13/2119?fbclid=IwZXh0bgNhZW0CMTAAAR12MbTsGzvoCBZkZD1SpjYkCBADydlwa9t1KeZZXdbJiDauOxYlh-AQHTk_aem_PxnmswyJIBY7p35guqmpEg'
    },
    {
        id: 7,
        topic: 'A Single-Tube Colorimetric Loop-Mediated Isothermal Amplification for Rapid Detection of SARS-CoV-2 RNA',
        author: 'Hongjaisee S, Kham-Kjing N, Musikul P, Daengkaokhew W, Kongson N, Guntala R, Jaiyapan N, Kline E, Panpradist N, Ngo-Giang-Huong N, et al.',
        publish: 'Published: September 2023',
        year: 2023,
        book: 'Diagnostics 2023, 13(19), 3040;https://doi.org/10.3390/diagnostics13193040',
        link: 'https://www.mdpi.com/2075-4418/13/19/3040'
    },
    {
        id: 6,
        topic: 'Antibody response to rabies pre-exposure vaccination among village health volunteers in a northern region of Thailand',
        author: 'Khamduang W, Hongjaisee S, Pothita T, Kongyai N, Surajinda S, Petsophonsakul W, Pornprasert S.',
        publish: 'Published: February 2022',
        year: 2022,
        book: 'Preventive veterinary medicine, 199, 105555. https://doi.org/10.1016/j.prevetmed.2021.105555',
        link: 'https://www.sciencedirect.com/science/article/abs/pii/S0167587721002993?via%3Dihub'
        
    },
    {
        id: 5,
        topic: 'Prevalences of SARS-CoV-2 RNA and anti-SARS-CoV-2 among at-risk populations in Chiang Mai and Lamphun provinces, Thailand, during November 2020-January 2021',
        author: 'Takalay S, Ngo-Giang-Huong N, Kongnim W, Mongkolwat P, Phoseng P, Wangsaeng N, Hongjaisee S, Butr-Indr B, Tragoolpua K, Jourdain G, Pornprasert S, Khamduang W.',
        publish: 'Published: February 2022',
        year: 2022,
        book: 'PloS one, 17(2), e0263127. https://doi.org/10.1371/journal.pone.0263127',
        link: 'https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0263127'
        
    },
    {
        id: 4,
        topic: 'Zika Virus Immunoglobulin G Seroprevalence among Young Adults Living with HIV or without HIV in Thailand from 1997 to 2017',
        author: 'Choyrum S, Wangsaeng N, Nechba A, Salvadori N, Saisom R, Achalapong J, Putiyanun C, Sabsanong P, Sangsawang S, Patamasingh Na Ayudhaya O, Jourdain G, Ngo-Giang-Huong N, Khamduang W.',
        publish: 'Published: February 2022',
        year: 2022,
        book: 'Viruses, 14(2), 368. https://doi.org/10.3390/v14020368',
        link: 'https://www.mdpi.com/1999-4915/14/2/368'
        
    },
    {
        id: 3,
        topic: 'Lack of Association between Adverse Pregnancy Outcomes and Zika Antibodies among Pregnant Women in Thailand between 1997 and 2015',
        author: 'Ngo-Giang-Huong N, Leroi C, Fusco D, Cressey TR, Wangsaeng N, Salvadori N, Kongyai N, Sirirungsi W, Lallemant M, Auewarakul P, Khamduang W, Jourdain G.',
        publish: 'Published: July 2021',
        year: 2021,
        book: 'Viruses, 13(8), 1423. https://doi.org/10.3390/v13081423',
        link: 'https://www.mdpi.com/1999-4915/13/8/1423'
        
    },
    {
        id: 2,
        topic: 'Soil-transmitted helminth infections in free-ranging non-human primates from Cameroon and Gabon',
        author: 'Sirima, C., Bizet, C., Hamou, H., Červená, B., Lemarcis, T., Esteban, A., Peeters, M., Mpoudi Ngole, E., Mombo, I. M., Liégeois, F., Petrželková, K. J., Boussinesq, M., & Locatelli, S.',
        publish: 'Published: July 2021',
        year: 2021,
        book: 'Parasites & vectors, 14(1), 354. https://doi.org/10.1186/s13071-021-04855-7',
        link: 'https://pubmed.ncbi.nlm.nih.gov/34225777/'
        
    },
    {
        id: 1,
        topic: 'Limitations of PCR detection of filarial DNA in human stools from subjects non-infected with soil-transmitted helminths',
        author: 'Maxime P. M. Doret, Hugues C. Nana-Djeunga, Narcisse Nzune-Toche, Sébastien D. S. Pion, Cédric B. Chesnais, Michel Boussinesq, Joseph Kamgno, Emmanuelle Varlet-Marie and Sabrina Locatelli',
        publish: 'Published: May 2021',
        year: 2021,
        book: 'Parasite, 28 (2021) 47. https://doi.org/10.1051/parasite/2021046',
        link: 'https://doi.org/10.1051/parasite/2021046'
        
    },
]

export default Publications