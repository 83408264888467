import React, { useEffect, useState } from 'react'
import './css/projects.css'

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator'

import { Link, Route, Routes } from 'react-router-dom'

import projects from '../localfiles/local-projects'

import ErrorPage from './error'

const Projects = () => {
    const handleUp = () =>{
        window.scrollTo({top:0})
    }

    const [active, setActive] = useState('')
    const handlePopup = (e) =>{
        const key = e.currentTarget.getAttribute('data-key')
        setActive(prevActive => prevActive === key ? '' : key)
    }

    useEffect(()=>{
        const popUp = () =>{
            if(active){
                const elements = document.querySelectorAll(`.containers ${active}`)
                elements.forEach(element =>{
                    element.classList.toggle('active')
                })
            }else{
                return
            }
        }
        popUp()
    },[active])

    // FILTER Catalogs
    const [filterInput, setFilterInput] = useState('')
    const [filteredData, setFilterData] = useState([])

    const [search, setSearch] = useState('')
    const handleSearch = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setSearch(value)

        const filtered = projects.filter((project)=>
            project.ini.toLowerCase().includes(value.toLowerCase())
        )
        setFilterData(filtered)
    }

    const [type, setType] = useState(false)
    const handleDropdown = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setType(value)

        const filtered = projects.filter((project) =>
            project.aim.toString().includes(value.toString())
        )
        setFilterData(filtered)  
    }

    const [Year, setYear] = useState(false)
    const handleYear = (e) =>{
        const value = e.target.value
        setYear(value)
        setFilterInput(value)

        const filtered = projects.filter((project) =>
            project.year.toString().includes(value.toString())
        )
        setFilterData(filtered)
    }

    const types = [
        {
            id: 0,
            label: 'All',
            value: false
        },
        {
            id: 1,
            label: 'Research Projects',
            value: 'Research Projects'
        },
        {
            id: 2,
            label: 'Implementation Projects',
            value: 'Implementation Projects'
        },
        {
            id: 3,
            label: 'Capacity Building Projects',
            value: 'Capacity Building Projects'
        },
    ]

    // const aims = [
    //     {
    //         id: 0,
    //         name: 'All',
    //         pillar: false
    //     },
    //     {
    //         id: 1,
    //         name: 'AIM1: Molecular characterization /epidemiology of pathogens of zoonotic potential',
    //         pillar: 1
    //     },
    //     {
    //         id: 2,
    //         name: 'AIM2: Identification of animal reservoirs',
    //         pillar: 2
    //     },
    //     {
    //         id: 3,
    //         name: 'AIM3: Identification of behavior risks of exposure and susceptible human populations',
    //         pillar: 3
    //     },
    //     {
    //         id: 4,
    //         name: 'AIM4: Identification of biodiversity rich areas/ anthropogenic pressure',
    //         pillar: 4
    //     },
    //     {
    //         id: 5,
    //         name: 'AIM5: New detection tests/ validation',
    //         pillar: 5
    //     },
    // ]

    const years = [
        {
            id: 0,
            year: false,
            label: 'All'
        },
        {
            id:3,
            year: 2024,
            label: 2024
        },
        {
            id:2,
            year: 2023,
            label: 2023
        },
        {
            id: 1,
            year: 2022,
            label: 2022
        },
    ]

    // Maximum containers
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerpage, setRowPerPage] = useState(8)

    // calculate data display
    const paginatedData = projects.slice(currentPage * rowsPerpage, (currentPage + 1) * rowsPerpage)
    // Handle page change
    const onPageChange = (e) => {
        setCurrentPage(e.page)
        setRowPerPage(e.rows)
        handleUp()
    }

    const projectRoutes = projects.map((project) => (
        <Route path={project.href} element={<project.element />} key={project.id}/>
    ))
    const dataToDisplay = filterInput ? filteredData : paginatedData
    const totalRecords = filterInput ? filteredData.length : projects.length;

    return(
        <div className="project">
            <div className="people-modal" style={{display: active? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>PROJECTS</h1>
                </div>
            </div>

            <Routes>
                <Route index element={
                   <div className='container'>
                    <div className="filter">
                        <div className="items search">
                            <div className="head">
                                <b>Search: </b>
                            </div>
                            <div className="search">
                                <InputText type="text" placeholder="enter search term..." onChange={handleSearch} value={search} disabled={type !== false || Year !== false}/>
                                <i className="pi pi-search"></i>
                            </div>
                        </div>
                            <div className="dropdown">
                                {/* FILTER by AIMS */}
                                    <div className="items dropdown aims">
                                        <div className="head">
                                            <b>Type: </b>
                                        </div>
                                        <div className="aims">
                                            <Dropdown value={type} onChange={handleDropdown} options={types} optionLabel='label' optionValue='value' placeholder='All' disabled={search !== '' || Year !== false}/>
                                        </div>
                                    </div>
                                {/* FILTER by YEAR */}
                                <div className="items dropdown year">
                                    <div className="head">
                                        <b>Year: </b>
                                    </div>
                                    <div className="year">
                                        <Dropdown value={Year} onChange={handleYear} options={years} optionLabel='label' optionValue='year' placeholder='All' disabled={search !== '' || type !== false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-activities">
                                {/* Search input */}
                            {dataToDisplay.map((project) => (
                            <div className={`containers ${project.id}`} key={project.id}>
                                <div className="items">
                                <div className="img">
                                    <img src={project.imgSrc} alt="item" loading='lazy'/>
                                    {/* <div className="tags">
                                        {Array.isArray(project.aim) && project.aim.map((aim) => (
                                            <div className="aim">
                                                {aim}
                                            </div>
                                        ))}
                                        <div className="year">
                                            {project.year}
                                        </div>
                                    </div> */}
                                </div>
                                <div className='ini'>
                                    <h2>{project.ini}</h2>
                                </div>
                                <div className='inform'>
                                    <p className='text'>{project.inform}</p>
                                </div>
                                <div className="btn">
                                    <Link to={`/projects/${project.href}`} id={project.href}>SEE MORE</Link>
                                </div>
                                </div>
                            </div>
                            ))}
                            
                        </div>    
                        <div className="paginator">
                            <Paginator
                                first={currentPage * rowsPerpage}
                                rows={rowsPerpage}
                                totalRecords={totalRecords}
                                onPageChange={onPageChange}
                            />
                        </div>
                   </div>
                }/>
                {projectRoutes}
                <Route path='*' element={<ErrorPage/>} />
            </Routes> 

        </div>
    )
}

export default Projects