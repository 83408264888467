import React, { useEffect, useState } from 'react'
import './css/news.css'

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator'

import { Link, Route, Routes } from 'react-router-dom'

import NewsData from '../localfiles/local-news'


// Others

import ErrorPage from './error'

const News = () => {
  const handleUp = () =>{
    window.scrollTo({top:0})
}

    const [active, setActive] = useState('')
    const handlePopup = (e) =>{
        const key = e.currentTarget.getAttribute('data-key')
        setActive(prevActive => prevActive === key ? '' : key)
    }

    useEffect(()=>{
        const popUp = () =>{
            if(active){
                const elements = document.querySelectorAll(`.containers ${active}`)
                elements.forEach(element =>{
                    element.classList.toggle('active')
                })
            }else{
                return
            }
        }
        popUp()
    },[active])

    // FILTER Catalogs
    const [filterInput, setFilterInput] = useState('')
    const [filteredData, setFilterData] = useState([])

    const [search, setSearch] = useState('')
    const handleSearch = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setSearch(value)

        const filtered = NewsData.filter((news)=>
            news.ini.toLowerCase().includes(value.toLowerCase())
        )
        setFilterData(filtered)
    }

    const [topic, setTopic] = useState(false)
    const handleDropdown = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setTopic(value)

        const filtered = NewsData.filter((news) =>
            news.cat.toString().includes(value.toString())
        )
        setFilterData(filtered)  
    }

    const [Year, setYear] = useState(false)
    const handleYear = (e) =>{
        const value = e.target.value
        setYear(value)
        setFilterInput(value)

        const filtered = NewsData.filter((news) =>
            news.year.toString().includes(value.toString())
        )
        setFilterData(filtered)
    }

    const topics = [
        {
            id: 0,
            name: 'All',
            group: false
        },
        {
            id: 1,
            name: 'NEWS & ACTIVITIES',
            group: 'news'
        },
        {
            id: 2,
            name: 'TRAINING',
            group: 'training'
        },
        {
            id: 3,
            name: 'WORKSHOP',
            group: 'workshop'
        },
        {
            id: 4,
            name: 'OTHERS',
            group: 'others'
        },
    ]

    const years = [
        {
            id: 0,
            year: false,
            label: 'All'
        },
        {
            id:4,
            year: 2025,
            label: 2025
        },
        {
            id:3,
            year: 2024,
            label: 2024
        },
        {
            id:2,
            year: 2023,
            label: 2023
        },
        {
            id: 1,
            year: 2022,
            label: 2022
        },
    ]

    // Maximum containers
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerpage, setRowPerPage] = useState(8)

    // calculate data display
    const paginatedData = NewsData.slice(currentPage * rowsPerpage, (currentPage + 1) * rowsPerpage)
    // Handle page change
    const onPageChange = (e) => {
        setCurrentPage(e.page)
        setRowPerPage(e.rows)
        handleUp()
    }

    const newsRoutes = NewsData.map((news) => (
        <Route path={`${news.cat}/${news.href}`} element={<news.element />} key={news.id} />
    ))
    const dataToDisplay = filterInput ? filteredData : paginatedData
    const totalRecords = filterInput ? filteredData.length : NewsData.length


    return(
        <div className="news">
            <div className="people-modal" style={{display: active? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>NEWS & ACTIVITIES</h1>
                </div>
            </div>

            <Routes>
                <Route index element={
                <div>
                    {/* Search input */}
                <div className="filter">
                    <div className="items search">
                        <div className="head">
                            <b>Search: </b>
                        </div>
                        <div className="search">
                            <InputText type="text" placeholder="enter search term..." onChange={handleSearch} value={search} disabled={topic !== false || Year !== false}/>
                            <i className="pi pi-search"></i>
                        </div>
                    </div>
                    <div className="dropdown">
                        {/* FILTER by Cat */}
                            <div className="items dropdown aims">
                                <div className="head">
                                    <b>Type: </b>
                                </div>
                                <div className="aims">
                                    <Dropdown value={topic} onChange={handleDropdown} options={topics} optionLabel='name' optionValue='group' placeholder='All' disabled={search !== '' || Year !== false}/>
                                </div>
                            </div>
                        {/* FILTER by YEAR */}
                            <div className="items dropdown year">
                                <div className="head">
                                    <b>Year: </b>
                                </div>
                                <div className="year">
                                    <Dropdown value={Year} onChange={handleYear} options={years} optionLabel='label' optionValue='year' placeholder='All' disabled={search !== '' || topic !== false}/>
                                </div>
                            </div>
                    </div>
                </div>
                  <div className="news-activities">
                    {dataToDisplay.map((news) => (
                      <div className={`containers ${news.id}`} key={news.id}>
                        <div className="items">
                          <div className="img">
                            <img src={news.imgSrc} alt="item" loading='lazy'/>
                            {/* <div className="tags">
                                {Array.isArray(news.aim) && news.aim.map((aim) => (
                                    <div className="aim">
                                        {aim}
                                    </div>
                                ))}
                                <div className="year">
                                    {news.year}
                                </div>
                            </div> */}
                          </div>
                          <div className='ini'>
                            <h2>{news.ini}</h2>
                          </div>
                          <div className='inform'>
                            <p className='text'>{news.inform}</p>
                          </div>
                          <div className="btn">
                            <Link to={`${news.cat}/${news.href}`} id={news.href}>SEE MORE</Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="paginator">
                        <Paginator
                            first={currentPage * rowsPerpage}
                            rows={rowsPerpage}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
                }/>
                {newsRoutes}
                <Route path='*' element={<ErrorPage/>} />

            </Routes>

        </div>
    )
}

export default News