// Workshop
import id1img from '../pages/news/workshop/Bilao Medical entomology course/cover.jpg'
import id1page from '../pages/news/workshop/Bilao Medical entomology course/index'
import id2img from '../pages/news/workshop/Engaging Laotian pharmacy students/cover.jpg'
import id2page from '../pages/news/workshop/Engaging Laotian pharmacy students/index'
import id3img from '../pages/news/workshop/Participatory approach in the context of One Health Social Sciences/cover.jpg'
import id3page from '../pages/news/workshop/Participatory approach in the context of One Health Social Sciences/index'
import id4img from '../pages/news/workshop/Preparing to respond to calls for tender/cover.JPG'
import id4page from '../pages/news/workshop/Preparing to respond to calls for tender/index'
import id14img from '../pages/news/workshop/Training workshop using heritage policies to support the One Health approach/cover.jpg'
import id14page from '../pages/news/workshop/Training workshop using heritage policies to support the One Health approach/index'

// News
import id15img from '../pages/news/news/2025 One Health Conference/cover.jpg'
import id15page from '../pages/news/news/2025 One Health Conference/index'
import id13img from '../pages/news/news/PREZODE_ASAMCO/cover.jpg'
import id13page from '../pages/news/news/PREZODE_ASAMCO/index'
import id12img from '../pages/news/news/First parasitology training course on nematodes at CIREN/cover.jpg'
import id12page from '../pages/news/news/First parasitology training course on nematodes at CIREN/index'
import id11img from '../pages/news/news/parasite_MEDCMU_241211/cover.jpg'
import id11page from '../pages/news/news/parasite_MEDCMU_241211/index'
import id10img from '../pages/news/news/Lunch Seminar_241209/cover.jpg'
import id10page from '../pages/news/news/Lunch Seminar_241209/index'
import id5img from '../pages/news/news/Boung Phao Food Festival/cover.jpg'
import id5page from '../pages/news/news/Boung Phao Food Festival/index'
import id6img from '../pages/news/news/What research should be conducted to prevent future epidemicspandemics in Southeast Asia/cover.jpg'
import id6page from '../pages/news/news/What research should be conducted to prevent future epidemicspandemics in Southeast Asia/index'
import id7img from '../pages/news/news/Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai Thailand/cover.jpg'
import id7page from '../pages/news/news/Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai Thailand/index'
import id8img from '../pages/news/news/The 14th International Public Health Conference among Greater Mekong Sub-regional Countries/cover.jpg'
import id8page from '../pages/news/news/The 14th International Public Health Conference among Greater Mekong Sub-regional Countries/index'

// training
import id9img from '../pages/news/training/Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022/cover.jpg'
import id9page from '../pages/news/training/Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022/index'


const NewsData = [
    {
        id:15,
        year: 2025,
        cat: 'news',
        imgSrc: id15img,
        ini: 'International One Health Conference. Faculty of Associated Medical Sciences, Chiang Mai University, Thailand',
        href: `2025 One Health Conference`,
        element: id15page,
        inform: `On 8th - 9th January 2025, the international conference 2025 One Health Conference: Tackling Air Pollution, Respiratory Diseases, Antimicrobial Resistance and Host Immunity at the Human, Animal, and Environment Interface
        has been organized by Faculty of Associated Medical Sciences, Chiang Mai University, Thailand at Shangri-La Hotel Chiang Mai, Thailand. For sharing about academic information and to support One-Health research topics in the future.
        In collaboration with professors and specialists from Faculty of Associated Medical Sciences, Faculty of Sciences, and Faculty of Veterinary Medicine Chiang Mai University. "PRESTO: Understanding and mitigating zoonotic spillover events in
        declining biodiversity hot-spots in Southeast Asia" has been presented by Asst. Prof. Woottichai Khamduang (the PRESTO coordinator in Thailand)`,
        active: 1
    },
    {
        id:14,
        year: 2024,
        cat: 'workshop',
        imgSrc: id14img,
        ini: 'Training workshop: using heritage policies to support the One Health approach',
        href: `Training workshop using heritage policies to support the One Health approach`,
        element: id14page,
        inform: `One Health is central for the attainment of global health and includes critical challenges such as the risk of pandemics from zoonotic disease emergence, antimicrobial resistance, or food insecurity through environmental degradation and contamination.
        Held with the support of IJL PRESTO, the British Academy funded research project “Towards Heritage-Sensitive Climate Change Mitigation Policy: Impulses from Indigenous Practice in Thailand” at Chiang Mai University, and the Agence Universitaire de la Francophonie (AUF) in Laos, the workshop explored an innovative concept to promote One Health from a participatory social science perspective: everyday environmental heritage. The activities involved 20 participants, including academics and students from the University of Health Sciences and the National University of Laos, international instructors with cutting-edge expertise in various facets of heritage studies and conservation, and researchers of IRD– plus members of local communities.
        The participatory setup of the workshop involved not only the training of the Lao participants but also opened mutual learning and exchange opportunities between the participants and the villagers themselves. They problematized the situation of their local ecosystem and the critical role of heritage therein, and expressed a demand for more universal education on these issues in Laos.
        `,
        active: 1
    },
    {
        id:13,
        year: 2024,
        cat: 'news',
        imgSrc: id13img,
        ini:'PREZODE initiative: kick-off meeting of the ASAMCO project in Laos and Thailand',
        href: `PREZODE_ASAMCO`,
        element: id13page,
        inform: `On December 13, the various parties and partners gathered in Bangkok (Thailand) to mark the official launch of the ASAMCO project in the region, the second component of the PREACTS project developed as part of the international PREZODE initiative.
        Hosted at Kasetsart University's Faculty of Veterinary Technology, the kick-off meeting opened with welcome speeches from Dr. Chongrak WACHRINRAT (President of Kasetsart University), Dr. Wuttinun RAKSAJIT (Dean of the Faculty of Veterinary Technology) and H.E. Sir Jean-Claude POIMBOEUF (French Ambassador to Thailand). He stressed the importance of the One Health approach in preventing the emergence and spread of zoonotic diseases, and thanked the work and collaboration of French scientific operators in Thailand and the region.`,
        active: 1
    },
    
    {
        id:12,
        year: 2024,
        cat: 'news',
        imgSrc: id12img,
        ini:'First parasitology training course on nematodes at CIREN',
        href: `First parasitology training course on nematodes at CIREN`,
        element: id12page,
        inform:`As part of IJL PRESTO activities, a parasitology workshop at CIREN (Nakaï, Laos) brought together 14 participants to learn about non-invasive sample collection and laboratory analysis of parasites with zoonotic transmission potential, with a specific focus on nematodes.`,
        active: 1
    },
    {
        id:11,
        year: 2024,
        cat: 'news',
        imgSrc: id11img,
        ini:'Explore oppotunities and strategies for improving education and research with Department of Parasitology, Faculty of Medicine, Chiang Mai University"',
        href: `parasite_MEDCMU_241211`,
        element: id11page,
        inform:`On December 11, 2024, Dr. Dorothée Missé, Dr.Dorothée Missé co-heads the “Biology of Viral Infection” Department at IRD MIVEGEC Unit, France
        met with the Department of Parasitology, Faculty of Medicine, Chiang Mai University, to explore opportunities and strategies for developing education and research collaboration.`,
        active: 1
    },
    {
        id:10,
        year: 2024,
        cat: 'news',
        imgSrc: id10img,
        ini:'LUNCH SEMINAR in the topic of "ARBOVIRUS TRANSMISSION AND PATHOGENESIS"',
        href: `Lunch Seminar_241209`,
        element: id10page,
        inform:`Dr Dorothée Missé co-heads the “Biology of Viral Infection” Department at IRD MIVEGEC Unit. She works on the pathogenesis of emerging and re-emerging viruses (Dengue, Chikungunya, Zika, Mayaro...viruses) with a focus on virus-host interactions and innate immunity and antiviral compound discovery. She is involved in various "One Health" projects in Africa  (Zimbabwe & Cameroon). Dr Missé is also a nominated member of the CBS2 Doctoral School office at the Montpellier University of Excellence.`,
        active: 1
    },
    {
        id:9,
        year: 2024,
        cat: 'news',
        imgSrc: id7img,
        ini:'Poster presentation in "SEAOHUN 2024 International Conference in Chiang Mai, Thailand"',
        href: `Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai, Thailand`,
        element: id7page,
        inform:`On September 19, 2024, Ms. Premmarin Inmonthian, a master's degree student of Assistant Professor Dr. Wuttichai Kamduang in the International Program of Medical Technology at Chiang Mai University, presented her research in a poster presentation titled "Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand." This took place during the SEAOHUN 2024 International Conference in Chiang Mai, Thailand, under the theme "One Health in Action," held at the Shangri-La Hotel, Chiang Mai.`,
        active: 1
    },
    {
        id:8,
        year: 2024,
        cat: 'news',
        imgSrc: id8img,
        ini:'The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.',
        href: `The 14th International Public Health Conference among Greater Mekong Sub-regional Countries`,
        element: id8page,
        inform:`On June 27 - 29, 2024, Miss Premarin Inmontian, a Master degree student in the field of Medical Technology (International Program) at the Faculty of Medical Technology, Chiang Mai University, with Assistant Professor Dr. Woottichai Khamduang as her advisor, participated and presented research results in the form of a poster presentation on the topic ""Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand at the international academic conference “The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.” The conference is theme was Meeting the Sustainable Development Goals through Improved Nutrition, Health Equity, and Climate Change Adaptation"" and was held at Pullman Luang Prabang Hotel, Luang Prabang, Lao People is Democratic Republic.`,
        active: 1
    },
    {
        id:7,
        year: 2023,
        cat: 'news',
        imgSrc: id6img,
        ini:'What research should be conducted to prevent future epidemicspandemics in Southeast Asia',
        href: `What research should be conducted to prevent future epidemicspandemics in Southeast Asia`,
        element: id6page,
        inform:`A brainstorming meeting on regional research strategies in the face of epidemics, initiated by the members of the IJL PRESTO, gathered about 40 participants was held in Chiang Mai (Thailand) in March 2023.`
    },
    {
        id:6,
        year: 2023,
        cat: 'news',
        imgSrc: id5img,
        ini:'Boung Phao Food Festival',
        href: `Boung Phao Food Festival`,
        element: id5page,
        inform:`Organised at the Ban Boung Phao health centre - one of the sites where the NutriLao study took place - the festival brought together local villagers as well as representatives from the village and district authorities, the Agence universitaire de la Francophonie (AUF), the University of Health Sciences, the World Food Programme and the Pierre Fabre Foundation. The aim of the event was to raise awareness of nutrition in general, as well as the prevention of orally-transmitted diseases such as opistorchiasis caused by eating raw fish`
    },
    {
        id:5,
        year: 2023,
        cat: 'workshop',
        imgSrc: id4img,
        ini:'Preparing to respond to calls for tender',
        href: `Preparing to respond to calls for tender`,
        element: id4page,
        inform:`In May 2023, discussion sessions were held during which the partners had the opportunity to express their needs and expectations of this new research network, in particular how to integrate the One Heath approach, which studies health from the human, animal and environmental perspectives, into their research projects.
Around thirty participants exchanged ideas to identify a research question relevant to all the members present. Two concept notes emerged from these discussions, with a view to responding to calls for projects in general and the PREZODE initiative in particular.`
    },
    {
        id:4,
        year: 2023,
        cat: 'workshop',
        imgSrc: id3img,
        ini:'Participatory approach in the context of One Health Social Sciences',
        href: `Participatory approach in the context of One Health Social Sciences`,
        element: id3page,
        inform:`In collaboration with the PRESTO team from Chiang Mai, a two-day workshop on the participatory approach in the context of One Health was held in Vientiane. Its aims were to explore the theoretical and methodological aspects of qualitative research in the context of public health in Laos, and to develop research skills through lectures and practical exercises. It was attended by 89 participants.`
    },
    {
        id:3,
        year: 2023,
        cat: 'workshop',
        imgSrc: id2img,
        ini:'Engaging Laotian pharmacy students',
        href: `Engaging Laotian pharmacy students`,
        element: id2page,
        inform:`Last July 19, 90 participants gathered at the Faculty of Pharmacy of the 
            University of Health Sciences (UHS) in Vientiane to share knowledge on two IRD-led 
            nutrition-related research projects in Laos: the IMMANA and NutriLao studies.
The Faculty of Pharmacy has been a major partner throughout the IMMANA and NutriLao projects, 
contributing distinctly to their success through administrative support as well as the 
secondment of highly qualified specialist staff members. The completion of the research offered 
a unique opportunity to share methodological approaches, experiences, and lessons learned with the next generation of scholarly minds in Laos. Testimonies and feedbacks from researchers inspired students on the practical problems they can tackle with these research methodologies, and how to translate them into actionable interventions and policies. Owing to the One Health relevance of nutrition and food safety in zoonotic diseases and the cross-boundary work of these projects – linking research in Chiang Mai, Thailand, with Vientiane Province, Laos – the event was also supported by the LMI Presto.
`
    },
    {
        id:2,
        year: 2023,
        cat: 'workshop',
        imgSrc: id1img,
        ini:'Bilao Medical entomology course',
        href: `Bilao Medical entomology course`,
        element: id1page,
        inform:`A workshop and a presentation of the project were organised at the Faculty of Pharmacy in Laos for around twenty students and teachers. The main principles of medical entomology were reviewed and techniques for collecting and analysing mosquitoes were taught.`
    },
    {
        id:1,
        year: 2022,
        cat: 'training',
        imgSrc: id9img,
        ini:'Disease Ecology Symposium and Training: 28 Nov - 2 Dec 2022',
        href: `Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022`,
        element: id9page,
        inform:`This program is designed for researchers, scientists, and post-graduate students interested in the fields of disease ecology, biodiversity or other related fields such as conservation medicine, veterinary epidemiology, zoology, parasitology and microbiology who are seeking field, laboratory and computational trainings in monitoring of biodiversity, wildlife population, wildlife-borne diseases, disease reservoirs and vectors in tropical area. At Tropical Disease Research Centre (TDRC), Faculty of Tropical Medicine, Mahidol University Kanchanaburi Campus (MUKA), Sai Yok, Kanchanaburi, Thailand.`
    },
]

export default NewsData