import './css/home.css'
// import prestologo from '../logo/presto-logo-color.png'

// News
import NewsData from '../localfiles/local-news'

import { FaShield } from 'react-icons/fa6'
import { AiOutlineAim } from 'react-icons/ai'
import { AiOutlineStop } from 'react-icons/ai'
import { FaArrowRightLong } from "react-icons/fa6";

import { useEffect, useState } from 'react'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Link, Route, Routes } from 'react-router-dom'

const Home = () => {

    const [scrolled, setScrolled] = useState(false);
    useEffect(()=>{
        const handleScroll = () =>{
            if(window.scrollY > 230){
                setScrolled(true)
            }
        };
        window.addEventListener('scroll', handleScroll)
        return () =>{
            window.removeEventListener('scroll', handleScroll)
        }
    },[])

    const handleRoute = NewsData.map((news) => (
        <Route path={`news/${news.cat}/${news.href}`} element={<news.element/>} key={news.id}/>
    ))

    return(
        <div className="homepage">
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>To understand how zoonotic infections can (re)emerge, spread, and how they can be stopped</h1>
                </div>
            </div>

            <div className="objectives">
                <div className="containers">
                    <div className="img"><FaShield/></div>
                    <div className="head">
                        <h3><b>PR</b>otect</h3>
                    </div>
                    <div className="ul">
                        <ul>
                            <li>Biodiversity</li>
                            <li>Land and pristine territories</li>
                            <li>Vulnerable animals and human populations</li>
                        </ul>
                    </div>
                </div>
                <div className="containers">
                    <div className="img"><AiOutlineAim/></div>
                    <div className="head">
                        <h3>d<b>E</b>tect</h3>
                    </div>
                    <div className="ul">
                        <ul>
                            <li>Zoonosis emergence</li>
                            <li>Spillover</li>
                            <li>Spillback</li>
                        </ul>
                    </div>
                </div>
                <div className="containers">
                    <div className="img"><AiOutlineStop/></div>
                    <div className="head">
                        <h3><b>STO</b>p</h3>
                    </div>
                    <div className="ul">
                        <ul>
                            <li>High risk activities</li>
                            <li>Emerging infectious diseases outbreaks</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="aboutus">
                <h2><Link to='aboutus'>LEARN MORE ABOUT US<FaArrowRightLong/></Link></h2>
            </div>
<hr />
            <div className="news">
                <Routes>
                    <Route index element={
                        <OwlCarousel
                        className='owl-theme'             
                        margin={0}
                        lazyLoad={true}
                        loop
                        items={1}
                        autoplay={true}
                        autoplayTimeout={7000}
                        autoplayHoverPause={true}
                        smartSpeed={1500}
                        >
                            {NewsData.map((news)=> (
                                news.active === 1 ?
                                <div className={`containers ${news.id}`} key={news.id}>
                                    <div className="text">
                                        <h3>{news.ini}</h3>
                                        <p>{news.inform}</p>
                                        <div className="link">
                                            <Link to={`news/${news.cat}/${news.href}`}>SEE MORE</Link>
                                        </div>
                                    </div>
                                
                                    <div className="img">
                                        <img src={news.imgSrc} alt='img' />
                                    </div>
                                </div> :
                                false
                            ))}
                        </OwlCarousel>
                    }/>
                    {handleRoute}
                </Routes>
            </div>
<hr />
        </div>
    )
}

export default Home